import { highlightText, isInBrowser } from '@helpers'
import { Box } from '@material-ui/core'
import makeStyles from '@material-ui/styles/makeStyles'
import renderBloks from '@renderBloks'
import { Icon } from '@system'
import get from 'lodash/get'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SbEditable from 'storyblok-react'
import { AccordionContext } from '../../contexts/accordion-context'

const useStyles = makeStyles((theme) => ({
  accordionSectionRoot: {
    margin: '0.75rem 0',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.tertiary,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.background.lightSlate}`,
  },
  titleContainer: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    fontWeight: '700',
    justifyContent: 'space-between',
  },
  title: ({ hasBackgroundImage }) => ({
    color: theme.palette.common.black,
    fontSize: '20px',
    fontWeight: hasBackgroundImage ? '300' : '700',
    padding: '0.75rem 1rem',
  }),
  expandIcon: {
    width: '24px',
    margin: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.black,
  },
  contentContainer: ({ isExpanded }) => ({
    maxHeight: isExpanded ? '1000vh' : '0',
    overflow: 'hidden',
    transition: `max-height ${isExpanded ? '1.5s' : '0.25s'} ease`,
  }),
  content: ({ isRoadmapContent }) => ({
    padding: isRoadmapContent ? '0.5rem 0' : '0.5rem 2rem 2rem',
  }),
}))

const AccordionSection = ({ blok, sectionIndex }) => {
  const { sectionTitle, expandedContent } = blok
  const {
    handleSectionToggle,
    hasBackgroundImage,
    textFragment,
    toggledSections,
  } = useContext(AccordionContext)
  const [content, setContent] = useState(expandedContent)
  const [title, setTitle] = useState(sectionTitle)
  const scrollToRef = useRef(null)
  const isRoadmapContent =
    get(expandedContent, '[0].component') === 'roadmapBlock'
  const isExpanded = toggledSections.includes(sectionIndex)
  const classes = useStyles({
    hasBackgroundImage,
    isExpanded,
    isRoadmapContent,
  })

  useEffect(() => {
    if (textFragment) {
      const markdown = expandedContent.filter(
        (contentItem) => contentItem.component === 'markdown'
      )
      const concatenatedText = markdown.reduce(
        (acc, curr) => acc + ` ${curr.markdown}`,
        title
      )
      const isFragmentInText = concatenatedText?.includes(textFragment)

      if (isFragmentInText) {
        handleSectionToggle(sectionIndex)
      } else {
        return
      }

      const highlightedContent = content.map((markdownBlok) => {
        const highlightedMarkdown = highlightText(
          markdownBlok.markdown,
          textFragment,
          true
        )

        return {
          ...markdownBlok,
          markdown: highlightedMarkdown,
        }
      })

      const highlightedTitle = highlightText(title, textFragment)

      setContent(highlightedContent)
      setTitle(highlightedTitle)

      if (isInBrowser) {
        window.setTimeout(() => {
          scrollToRef.current.scrollIntoView({ block: 'center' })
        }, 1000)
      }
    }
  }, [textFragment])

  return (
    <SbEditable content={blok}>
      <Box className={classes.accordionSectionRoot} ref={scrollToRef}>
        <Box
          className={classes.titleContainer}
          onClick={() => handleSectionToggle(sectionIndex)}
        >
          <Box className={classes.title}>{!!title && title}</Box>
          <Box className={classes.expandIcon}>
            <Icon>{isExpanded ? 'subtract' : 'add'}</Icon>
          </Box>
        </Box>
        <Box className={classes.contentContainer}>
          <Box className={classes.content}>
            {!!content && renderBloks(content)}
          </Box>
        </Box>
      </Box>
    </SbEditable>
  )
}

export default AccordionSection
