import useTextFragment from '@hooks/use-text-fragment'
import React, { createContext, useState } from 'react'

const AccordionContext = createContext()
const { Provider } = AccordionContext

const AccordionProvider = ({
  children,
  hasBackgroundImage,
  hasOneSectionToggle,
}) => {
  const [toggledSections, setToggledSections] = useState([])
  const textFragment = useTextFragment()

  const handleSectionToggle = (index) => {
    if (hasOneSectionToggle) {
      if (toggledSections.includes(index)) {
        return setToggledSections([])
      }

      return setToggledSections([index])
    }

    if (toggledSections.includes(index)) {
      return setToggledSections((prevToggledSections) =>
        prevToggledSections.filter((section) => section !== index)
      )
    }

    return setToggledSections([...toggledSections, index])
  }

  return (
    <Provider
      value={{
        handleSectionToggle,
        hasBackgroundImage,
        textFragment,
        toggledSections,
      }}
    >
      {children}
    </Provider>
  )
}

export { AccordionContext, AccordionProvider }
