import { useEffect, useState } from 'react'

const useTextFragment = () => {
  const [textFragment, setTextFragment] = useState(null)

  useEffect(() => {
    const observer = new PerformanceObserver((list, _obs) => {
      const perfEntries = list.getEntries()
      const textFragment = perfEntries?.[0]?.name?.split('#:~:text=')?.[1]

      if (textFragment) {
        const decodedTextFragment = decodeURIComponent(textFragment)
        setTextFragment(decodedTextFragment)
      }
    })

    observer.observe({ buffered: true, type: 'navigation' })
  }, [])

  return textFragment
}

export default useTextFragment
